import React from "react";
import { Input } from "..";
import styles from "./styles.module.sass";
import { Controller, FieldValues, UseFormSetError } from "react-hook-form";
import { TextAreaGenericField } from "../../../../FormElements/NoRedux/TextAreaGenericField";

interface IProps {
  isReactivating: boolean;
  control: any;
  isEditing: boolean;
  watchers: any;
  valid_promo_code: any;
  highestRank?: number;
  countryISOCode?: string;
  setError: UseFormSetError<FieldValues>;
}
const DefaultPromocodeInputs: React.FC<IProps> = ({
  isReactivating,
  control,
  isEditing,
  watchers,
  valid_promo_code,
  countryISOCode,
  highestRank,
  setError,
}) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "24px",
        flexDirection: "column",
      }}
    >
      <Input
        setError={setError}
        labelClassName={styles.inputLabel}
        disabled={isReactivating}
        rules={{
          required: {
            value: true,
            message: "Title is required",
          },
          maxLength: {
            value: 30,
            message: "Title must be 30 characters or less",
          },
        }}
        type={"text"}
        label="Promo code title (English)"
        placeholder={"Ex. 20% off from all menu"}
        name={"title"}
        control={control}
      />
      <Input
        setError={setError}
        labelClassName={styles.inputLabel}
        disabled={isReactivating}
        name={"ar_title"}
        control={control}
        rules={{
          required: {
            value: true,
            message: "AR Title is required",
          },
          maxLength: {
            value: 30,
            message: "AR Title must be 30 characters or less",
          },
        }}
        label="Promo code title (بالعربية)"
        placeholder={"Ex. 20% off from all menu"}
        type="text"
      />

      {!isEditing && (
        <>
          <Input
            setError={setError}
            labelClassName={styles.inputLabel}
            disabled={isReactivating}
            name={"promo_code"}
            label="Promo code"
            placeholder={"Ex: Koinz-20"}
            control={control}
            rules={{
              required: {
                value: true,
                message: "Promo code is required",
              },
            }}
          />
          {watchers.promo_code && (
            <label
              className={styles.promoCodeErrorMessage}
              style={{
                color: valid_promo_code ? "green" : "red",
                transform: "translateY(-6px)",
              }}
            >
              This Promo code {watchers.promo_code} is{" "}
              {!valid_promo_code && "not "} valid
            </label>
          )}
        </>
      )}

      <Controller
        name={"desc"}
        control={control}
        rules={{
          required: {
            value: true,
            message: "Description is required",
          },
        }}
        render={({
          field: { onChange, value, ref, onBlur },
          fieldState: { invalid, error, isTouched, isDirty },
        }) => (
          <>
            <TextAreaGenericField
              name="desc"
              label="Promo code description"
              disabled={isReactivating}
              placeholder={"Ex. 20% off from all menu"}
              className={styles.select}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              error={error || invalid}
              touched={isTouched}
            />
          </>
        )}
      />

      <Controller
        name={"ar_desc"}
        control={control}
        rules={{
          required: {
            value: true,
            message: "AR Description is required",
          },
        }}
        render={({
          field: { onChange, value, ref, onBlur },
          fieldState: { invalid, error, isTouched },
        }) => (
          <>
            <TextAreaGenericField
              name="ar_desc"
              label="Promo code description in (بالعربية)"
              disabled={isReactivating}
              placeholder={"Ex. 20% off from all menu"}
              className={styles.select}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              error={error || invalid}
              touched={isTouched}
            />
          </>
        )}
      />

      <Input
        setError={setError}
        labelClassName={styles.inputLabel}
        className={styles.rankInput}
        control={control}
        rules={{
          required: {
            value: true,
            message: "Rank is required",
          },
          min: {
            value: 1,
            message: "Rank should be greater than or equal 0",
          },
          max: {
            value: 1000,
            message: "Rank should be less than or equal 1000",
          },
        }}
        name="rank"
        label="Promo code Rank"
        placeholder={"0 - 1000"}
        highestRank={highestRank}
        countryISOCode={countryISOCode}
        type="number"
      />
    </div>
  );
};

export default DefaultPromocodeInputs;
