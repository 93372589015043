import { useEffect, useState } from "react";
import { store } from "../../../../redux-store/configureStore";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { clearPrevState } from "../../redux-store/actions/promoCodeActions";
import { PromocodeTarget, ToggleStatus } from "../../types";
import { mutations } from "../helpers/mutations";
import { getSystemPromoCode } from "../../../../redux-store/actions/getSystemPromoCodesActions";
interface IPromocodeSubmit {
  watchers: any;
  onSubmitPromoTypes: "create" | "edit" | "reactivate" | "duplicate";
  initialValues: any;
  promo_id: string;
  country_iso_code: string;
  promocodeTarget: PromocodeTarget;
  checkPromocodeRankData: {
    rank?: any;
    promo_code?: any;
  };
  highestRank: number;
  submittingStatus?: LoadingStatus;
  handleDrawerClose: () => void;
}
export const usePromocodeFromSubmit = ({
  watchers,
  onSubmitPromoTypes,
  initialValues,
  promo_id,
  country_iso_code,
  promocodeTarget,
  checkPromocodeRankData,
  highestRank,
  handleDrawerClose,
  submittingStatus,
}: IPromocodeSubmit) => {
  /**
   * promo ranking
   */

  let highestRankState;
  const [invalidRank, setInvalidRank] = useState(false);
  const handleCloseOverwriteRankingPopup = () => setInvalidRank(false);

  useDeepCompareEffect(() => {
    if (
      country_iso_code &&
      Object(highestRank).hasOwnProperty(country_iso_code)
    ) {
      highestRankState = highestRank[country_iso_code as string];
    }
  }, [country_iso_code, highestRank]);

  const validateRankInEdit = (value): any => {
    return Number(value) > highestRankState || Number(value) > 1000
      ? `The highest rank is ${Math.min(highestRankState, 1000)}`
      : undefined;
  };

  const validateRank = (value): any => {
    return Number(value) > highestRankState + 1 || Number(value) > 1000
      ? `The highest rank is ${Math.min(highestRankState + 1, 1000)}`
      : undefined;
  };
  const toggle_users = useDeepCompareMemo(
    () =>
      promocodeTarget === PromocodeTarget.universal
        ? ToggleStatus.enable
        : ToggleStatus.disable,
    [promocodeTarget]
  );
  const handleFormSubmit = (values) => {
    const submissionData = {
      ...values,
      country_iso_code,
    };

    if (
      Number(watchers.rank) === checkPromocodeRankData?.rank &&
      watchers.promo_code !== checkPromocodeRankData?.promo_code
    ) {
      setInvalidRank(true);
    } else {
      mutations[onSubmitPromoTypes]({
        values: submissionData,
        initialValues,
        promo_id,
        country_iso_code,
        toggle_users,
        promocode_target: promocodeTarget,
      });
    }
  };

  const handleOverwriteRankSubmit = (values) => {
    mutations[onSubmitPromoTypes]({
      values,
      initialValues,
      promo_id,
      country_iso_code,
      toggle_users,
      promocode_target: promocodeTarget,
    });
  };

  useEffect(() => {
    if (submittingStatus === LoadingStatus.success) {
      handleDrawerClose();
      store.dispatch(
        getSystemPromoCode({
          page: 0,
          per_page: 15,
          filters: undefined,
        })
      );
    }
  }, [submittingStatus]);
  useEffect(() => {
    if (submittingStatus !== LoadingStatus.loading) {
      store.dispatch(clearPrevState());
    }
  }, [submittingStatus, store.dispatch]);
  return {
    handleFormSubmit,
    handleCloseOverwriteRankingPopup,
    invalidRank,
    validateRankInEdit,
    validateRank,
    handleOverwriteRankSubmit,
    setInvalidRank,
  };
};
